<template>
  <div class="meldung" >
    <transition name="slide-x-transition" >
    <div v-if="doubletten.length > 0" >
    <v-icon color="blue" small>mdi-information</v-icon>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <small v-bind="attrs" v-on="on" ><span class="text-decoration-underline">{{ doubletten.length }} {{doubletten.length == 1 ? 'Person' : 'Personen'}} </span> mit gleichen Nachnamen</small>
        </template>
        <span v-html="displayedDoubletten"></span>
      </v-tooltip>
    </div>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  props: ['items', 'input'],
  name: "check-name",
  data() {
    return {
      lock: false,
    }
  },

  computed: {
    doubletten() {
      if(this.input == null || this.input === ''){
        return [];
      }
          return this.items.filter(i => i.nachname.toLowerCase().indexOf(this.input.toLowerCase()) > -1);
    },

    displayedDoubletten(){
      if(this.input == null || this.input === ''){
        return [];
      }
      const showedPersons = 15;
        const filteredPersonen = this.items.filter(i => i.nachname.toLowerCase().indexOf(this.input.toLowerCase()) > -1);
      const displayedPersonne = filteredPersonen.slice(0, showedPersons);

      const personenInfos = displayedPersonne.map((person) => {
        return `${person.anrede} ${person.vorname} ${person.nachname} - ${person.firma}`
      }).join("<br/>");

        return `${personenInfos} ${filteredPersonen.length > showedPersons ? '<br/>... + ' + (filteredPersonen.length - showedPersons) : '' }`
    }
  },
  methods: {}
}

</script>

<style scoped>
.meldung { height: 24px;}
</style>
